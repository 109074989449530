// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-actus-js": () => import("./../../../src/pages/actus.js" /* webpackChunkName: "component---src-pages-actus-js" */),
  "component---src-pages-chirurgien-main-coude-epaule-paris-js": () => import("./../../../src/pages/chirurgien-main-coude-epaule-paris.js" /* webpackChunkName: "component---src-pages-chirurgien-main-coude-epaule-paris-js" */),
  "component---src-pages-consultation-js": () => import("./../../../src/pages/consultation.js" /* webpackChunkName: "component---src-pages-consultation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-en-clinic-js": () => import("./../../../src/pages/en/clinic.js" /* webpackChunkName: "component---src-pages-en-clinic-js" */),
  "component---src-pages-en-consultation-js": () => import("./../../../src/pages/en/consultation.js" /* webpackChunkName: "component---src-pages-en-consultation-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-overview-js": () => import("./../../../src/pages/en/overview.js" /* webpackChunkName: "component---src-pages-en-overview-js" */),
  "component---src-pages-en-team-js": () => import("./../../../src/pages/en/team.js" /* webpackChunkName: "component---src-pages-en-team-js" */),
  "component---src-pages-en-testimonials-js": () => import("./../../../src/pages/en/testimonials.js" /* webpackChunkName: "component---src-pages-en-testimonials-js" */),
  "component---src-pages-equipe-js": () => import("./../../../src/pages/equipe.js" /* webpackChunkName: "component---src-pages-equipe-js" */),
  "component---src-pages-hospitalisation-js": () => import("./../../../src/pages/hospitalisation.js" /* webpackChunkName: "component---src-pages-hospitalisation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-message-envoye-js": () => import("./../../../src/pages/message-envoye.js" /* webpackChunkName: "component---src-pages-message-envoye-js" */),
  "component---src-pages-nos-patients-l-ont-fait-js": () => import("./../../../src/pages/nos-patients-l-ont-fait.js" /* webpackChunkName: "component---src-pages-nos-patients-l-ont-fait-js" */),
  "component---src-pages-temoignages-js": () => import("./../../../src/pages/temoignages.js" /* webpackChunkName: "component---src-pages-temoignages-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-actu-template-js": () => import("./../../../src/templates/actuTemplate.js" /* webpackChunkName: "component---src-templates-actu-template-js" */),
  "component---src-templates-cat-template-en-js": () => import("./../../../src/templates/catTemplate-en.js" /* webpackChunkName: "component---src-templates-cat-template-en-js" */),
  "component---src-templates-cat-template-js": () => import("./../../../src/templates/catTemplate.js" /* webpackChunkName: "component---src-templates-cat-template-js" */),
  "component---src-templates-page-template-en-js": () => import("./../../../src/templates/pageTemplate-en.js" /* webpackChunkName: "component---src-templates-page-template-en-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/pageTemplate.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-patients-template-js": () => import("./../../../src/templates/patientsTemplate.js" /* webpackChunkName: "component---src-templates-patients-template-js" */),
  "component---src-templates-video-template-js": () => import("./../../../src/templates/videoTemplate.js" /* webpackChunkName: "component---src-templates-video-template-js" */)
}

